<template>
  <div class="account">
    <div class="mt20">
      <a-form layout="inline">
        <a-form-item label="帐号">
          <a-input
            v-model="search.username"
            placeholder="请输入帐号名称"
          ></a-input>
        </a-form-item>

        <a-form-item label="手机号">
          <a-input v-model="search.mobile" placeholder="请输入手机号"></a-input>
        </a-form-item>

        <a-form-item>
          <a-button @click="searchAct" type="primary">查询</a-button>
          <a-button @click="cancelAct" class="ml10">取消</a-button>
          <a-button
            class="ml10"
            type="primary"
            icon="plus"
            @click="addAccountAct()"
            >添加代理</a-button
          >
        </a-form-item>
      </a-form>
    </div>

    <div class="mt20">
      <div class="wxb-table-white">
        <a-table
          :scroll="{ x: true, y: 600 }"
          rowKey="account_id"
          :columns="columns"
          :pagination="pagination"
          @change="handleTableChange"
          :data-source="datas"
        >
          <div
            class="flex alcenter center"
            slot="role"
            slot-scope="role, record"
          >
            <span
              class="ant-tag"
              :class="role == 1 ? 'ant-tag-green' : 'ant-tag-red'"
              >{{ role == 1 ? "可创建应用" : "不可创建应用" }}</span
            >
          </div>

          <div
            class="flex alcenter center cl-theme"
            slot="create_shop_count"
            slot-scope="record, index"
          >
            {{ record.create_shop_count }}/{{ record.num }}
          </div>

          <template slot="img" slot-scope="text, record">
            <img :src="record.customerUrl" width="48" />
          </template>
          <template slot="action" slot-scope="text, record">
            <div class="flex center">
              <a-dropdown placement="bottomRight">
                <span class="more-act">
                  <i class="iconfont iconmore_gray"></i>
                </span>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a
                      class="menu-act"
                      href="javascript:;"
                      @click="seeShopAct(record)"
                    >
                      <i class="iconfont ft14 iconshop_operation01"></i>
                      <span class="ml10">查看商铺</span>
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      class="menu-act"
                      href="javascript:;"
                      @click="editShopAct(record)"
                    >
                      <i class="iconfont ft14 iconedit"></i>
                      <span class="ml10">编辑</span>
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      class="menu-act"
                      href="javascript:;"
                      @click="delShopAct(record)"
                    >
                      <i class="iconfont ft14 icondelete"></i>
                      <span class="ml10">删除</span>
                    </a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </template>
        </a-table>

        <a-modal
          title="编辑账号"
          :width="400"
          :visible="visible"
          @cancel="handleCancel"
        >
          <template slot="footer">
            <a-button key="back" @click="handleCancel"> 取消 </a-button>
            <a-button
              key="submit"
              type="primary"
              @click="onSubmit"
              :loading="confirmLoading"
            >
              保存
            </a-button>
          </template>
          <div>
            <div class="flex mt10">
              <a-input
                class="woyaou-input"
                style="width: 460px"
                prefix="名称"
                placeholder="请输入账号姓名"
                v-model="user.username"
                :maxLength="20"
              >
              </a-input>
            </div>
            <div class="flex mt20">
              <a-input
                class="woyaou-input"
                type="password"
                style="width: 460px"
                prefix="密码"
                placeholder="请输入账号密码"
                v-model="user.password"
                :maxLength="20"
              >
              </a-input>
            </div>
            <!-- <div class="flex  mt20">
						<a-radio-group name="radioGroup" v-model="user.role">
							<a-radio :value="0">
								不可创建应用
							</a-radio>
							<a-radio :value="1">
								可创建应用
							</a-radio>
						</a-radio-group>
					</div> -->
            <div class="flex mt20" v-if="user.role == 1">
              <a-input
                class="woyaou-input"
                style="width: 460px"
                prefix="创建数量"
                value="1"
                v-model="user.num"
                :maxLength="10"
              >
              </a-input>
            </div>
          </div>
        </a-modal>

        <div v-if="seeShopVisible">
          <see-shop
            :visible="seeShopVisible"
            :account_id="account_id"
            @cancel="cancelSeeShop"
          ></see-shop>
        </div>

        <div v-if="addAccountVisible">
          <add-account
            :visible="addAccountVisible"
            @cancel="cancelAddAccount"
            @ok="okAddAccount"
          ></add-account>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listMixin } from "../../common/mixin/list.js";
import seeShop from "./components/index/modal/seeShop.vue";
import addAccount from "./components/index/modal/addAccount.vue";
export default {
  mixins: [listMixin],
  components: {
    seeShop,
    addAccount,
  },
  data() {
    return {
      loading: false,
      confirmLoading: false,
      visible: false,
      seeShopVisible: false,
      addAccountVisible: false,
      account_id: 0,
      pagination: {
        current: 1,
        pageSize: 50, //每页中显示10条数据
        total: 0,
      },
      search: {
        username: "",
        mobile: "",
        keyword: "",
      },
      columns: [
        {
          width: 200,
          title: "编号",
          dataIndex: "account_id",
          align: "center",
          ellipsis: true,
        },
        {
          width: 150,
          title: "帐号名称",
          dataIndex: "username",
          align: "center",
          ellipsis: true,
        },
        {
          width: 150,
          title: "手机号",
          dataIndex: "mobile",
          align: "center",
          ellipsis: true,
        },
        // {
        //   width: 100,
        //   title: "客服",
        //   dataIndex: "customerUrl",
        //   align: "center",
        //   scopedSlots: { customRender: "img" },
        // },
        // {
        //   width: 200,
        //   title: "归属地",
        //   dataIndex: "address",
        //   align: "center",
        //   ellipsis: true,
        // },
        // {title: '账号角色',dataIndex: 'role',align: 'center',scopedSlots: {customRender: 'role'}},
        {
          width: 200,
          title: "开通时间",
          dataIndex: "add_time_format",
          align: "center",
          ellipsis: true,
        },
        {
          width: 200,
          title: "最后活跃时间",
          dataIndex: "last_login_time_format",
          align: "center",
          ellipsis: true,
        },
        {
          width: 100,
          title: "名下商铺",
          dataIndex: "shopCount",
          align: "center",
          ellipsis: true,
        },
        // {title: '创建小程序',key: 'create_shop_count',align: 'center',scopedSlots: {customRender: 'create_shop_count'},width:150},
        {
          width: 100,
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      datas: [],
      user: {},
    };
  },
  created() {},
  methods: {
    getLists() {
      console.log(this.search);

      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/admin/getAccountList", {
          // keyword: this.search.keyword,
          username: this.search.username,
          mobile: this.search.mobile,
          size: this.pagination.pageSize,
          page: this.pagination.current,
        })
        .then((res) => {
          this.pagination.total = res.total;
          this.datas = res.list;
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.getLists();
    },

    //查看商铺
    seeShopAct(record) {
      let fd = new FormData();
      fd.append("accountId", record.account_id);
      this.$http.api("platform/admin/getAccountShopList", fd).then((res) => {
        let base_url = record.production_address;
        window.open(
          base_url + "auth?shop_token=" + encodeURIComponent(res.shop_token)
        );
      });

      // this.account_id = record.account_id;
      // this.seeShopVisible = true;
    },
    cancelSeeShop() {
      this.seeShopVisible = false;
    },
    //编辑商铺
    editShopAct(record) {
      this.user = record;
      this.user.password = "";
      this.visible = true;
    },
    handleCancel() {
      this.visible = false;
    },
    onSubmit() {
      if (this.confirmLoading == true) return;
      this.confirmLoading = true;
      this.$http
        .api("platform/admin/editAccount", {
          account_id: this.user.account_id,
          username: this.user.username,
          password: this.user.password,
          role: this.user.role,
          num: this.user.num,
        })
        .then((res) => {
          this.$message.success("编辑成功", 1, () => {
            this.visible = false;
            this.confirmLoading = false;
            this.getLists();
          });
        })
        .catch((res) => {
          console.log(res);
          this.confirmLoading = false;
        });
    },

    delShopAct(record) {
      this.$confirm({
        title: "删除后数据无法恢复，确认继续删除吗？",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          return new Promise((resolve, reject) => {
            this.$http
              .api("platform/admin/delAccount", {
                id: record.account_id,
              })
              .then((res) => {
                this.$message.success("删除成功");
                this.getLists();
              })
              .catch((res) => {
                console.log(res);
              })
              .finally(() => {
                resolve();
              });
          });
        },
      });
    },

    //添加账号
    addAccountAct() {
      this.addAccountVisible = true;
    },
    cancelAddAccount() {
      this.addAccountVisible = false;
    },
    okAddAccount() {
      this.addAccountVisible = false;
      this.getLists();
    },
  },
};
</script>

<style></style>

<template>
	<div>
		<a-modal title="添加账号" :width="540" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					保存
				</a-button>
			</template>
			<a-spin :spinning="false">
				<div>
					<div class="flex  mt10">
						<a-input class="woyaou-input" style="width: 460px;" prefix="名称" placeholder="请输入账号姓名" v-model="form.username" :maxLength="20">
						</a-input>
					</div>
					<div class="flex  mt20">
						<a-input class="woyaou-input" style="width: 460px;" prefix="手机号" placeholder="请输入手机号" v-model="form.mobile" :maxLength="20">
						</a-input>
					</div>
					<div class="flex  mt20">
						<a-input class="woyaou-input" type="password" style="width: 460px;" prefix="密码" placeholder="请输入账号密码" v-model="form.password" :maxLength="20">
						</a-input>
					</div>
					<!-- <div class="flex  mt20">
						<a-radio-group name="radioGroup" v-model="form.role">
							<a-radio :value="0">
								不可创建应用
							</a-radio>
							<a-radio :value="1">
								可创建应用
							</a-radio>
						</a-radio-group>
					</div> -->
					<div class="flex  mt20" v-if="form.role == 1">
						<a-input class="woyaou-input" style="width: 460px;" prefix="创建数量" value="1" v-model="form.num" :maxLength="10">
						</a-input>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				form: {
					username:'',
					mobile:'',
					password:'',
					role:0,
					num:1,
				}
			}
		},
		methods: {
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('platform/admin/addAccount',{
					username:this.form.username,
					mobile:this.form.mobile,
					password:this.form.password,
					role:this.form.role,
					num:this.form.num
				}).then(res=>{
					this.$message.success('添加成功',1,()=>{
						this.confirmLoading=false;
						this.$emit('ok');
					})
					
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
		}
	}
</script>

<style>

</style>
